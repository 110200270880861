const CirclePrev = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3333 20C38.3333 30.1252 30.1252 38.3333 20 38.3333C9.87478 38.3333 1.66667 30.1252 1.66667 20C1.66667 9.87478 9.87478 1.66667 20 1.66667C30.1252 1.66667 38.3333 9.87478 38.3333 20ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM22.2559 14.7192C22.5814 14.364 22.5814 13.7882 22.2559 13.433C21.9305 13.0779 21.4028 13.0779 21.0774 13.433L16.0774 18.8898C15.752 19.245 15.752 19.8208 16.0774 20.176L21.0774 25.6328C21.4029 25.9879 21.9305 25.9879 22.2559 25.6328C22.5814 25.2776 22.5814 24.7018 22.2559 24.3466L17.8452 19.5329L22.2559 14.7192Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default CirclePrev
